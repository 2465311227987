import { ProfileCreateState, ProfilesState, ProfileState } from '../models/profiles.model'
import { createSelector } from '@ngrx/store'

export const selectProfileFeature = (state: { profile: ProfileState }) => state.profile

export const selectProfilesFeature = (state: { profiles: ProfilesState }) => state.profiles
export const selectProfileCreateFeature = (state: { profileCreate: ProfileCreateState }) => state.profileCreate
export const selectProfileState = createSelector(selectProfileFeature, (state) => state)
export const selectProfilesState = createSelector(selectProfilesFeature, (state) => state)
export const selectProfileCreateState = createSelector(selectProfileCreateFeature, (state) => state)
