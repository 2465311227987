import { formatDate } from '@angular/common'

export interface Profile {
    id: number
    first_name: string
    last_name: string
    email: string | null
    mobile_number: string
    psira_number: string | null
    role: ProfileRole
    avatar: string | null
    uid?: string | null
    id_number: string
    created_at: Date
}

export interface CreateUserFlag {
    createUser: boolean
}

export type ProfileDto = Pick<
    Profile,
    'first_name' | 'last_name' | 'email' | 'mobile_number' | 'psira_number' | 'role' | 'avatar' | 'id_number' | 'uid'
> &
    CreateUserFlag

export type ProfileUpdateDto = Pick<
    Profile,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'mobile_number'
    | 'psira_number'
    | 'role'
    | 'avatar'
    | 'id_number'
    | 'uid'
> &
    CreateUserFlag

export interface ProfileState {
    isLoading: boolean
    profile: Profile | null
}

export interface ProfilesState {
    isLoading: boolean
    profiles: Profile[]
}

export interface ProfileCreateState {
    isLoading: boolean
    error: string
    profile: Profile | null
}

export interface ProfileUpdateState {
    updateProfileIsLoading: boolean
    updateProfileError: string
    updateProfileSuccess: boolean
}

export interface ProfileDetailsState {
    profileDetailsIsLoading: boolean
    profileDetailsError: string
    profileDetailsSuccess: boolean
    profileDetails: Profile | null
}

export enum ProfileRole {
    ADMIN = 'ADMIN',
    GUARD = 'GUARD',
    CONTROLLER = 'CONTROLLER',
    RO = 'RO',
}
