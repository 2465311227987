import { Injectable } from '@angular/core'
import {
    PostgrestMaybeSingleResponse,
    PostgrestResponse,
    PostgrestSingleResponse,
    SupabaseClient,
    User,
} from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Profile, ProfileDto, ProfileRole, ProfileUpdateDto } from '../models/profiles.model'
import { noop, Observable, Subscriber } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private supabase!: SupabaseClient

    constructor(private supabaseService: SupabaseService) {
        this.supabase = this.supabaseService.getClient()
    }

    profile(uid: string): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .eq('uid', uid)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    profileDetails(id: number): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .eq('id', id)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    profiles(): Observable<Profile[]> {
        return new Observable((subscriber: Subscriber<Profile[]>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .then((result: PostgrestResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    createProfile(profile: ProfileDto): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            try {
                const { createUser, ...profileData } = profile

                profileData.mobile_number = `27${profileData.mobile_number}`

                this.supabase
                    .from('profiles')
                    .insert(profileData)
                    .select()
                    .then(async (result: PostgrestResponse<Profile>) => {
                        if (result.error != null) {
                            return subscriber.error(result.error.message)
                        }
                        if (result.data?.length === 1 && createUser) {
                            if (profileData.role === ProfileRole.RO) {
                                await this.createResponseOfficerAuthUser(profileData.mobile_number!)
                            } else {
                                await this.createUser(profileData.email!)
                                subscriber.next(result.data![0])
                            }
                        }
                        return subscriber.next(result.data![0])
                    })
            } catch (e) {
                return subscriber.error('Could not create profile')
            }
        })
    }

    async createUser(email: string) {
        const otpResponse = await this.supabase.auth.signInWithOtp({
            email,
            options: {
                shouldCreateUser: true,
            },
        })

        if (otpResponse.error) {
            return otpResponse.error.message
        }
        if (otpResponse.data?.user) {
            const user = otpResponse.data.user as User

            return user!.id
        }
        return noop()
    }

    async createResponseOfficerAuthUser(phone: string) {
        const otpResponse = await this.supabase.auth.signInWithOtp({
            phone: `+${phone}`,
            options: {
                shouldCreateUser: true,
            },
        })

        if (otpResponse.error) {
            return otpResponse.error.message
        }
        if (otpResponse.data?.user) {
            const user = otpResponse.data.user as User

            return user!.id
        }
        return noop()
    }

    updateProfile(profile: ProfileUpdateDto): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            try {
                const { createUser, id, ...profileData } = profile

                const query = this.supabase
                    .from('profiles')
                    .update({ ...profileData })
                    .eq('id', id)
                    .single()

                query.then(async (result: PostgrestSingleResponse<Profile>) => {
                    if (result.error != null) {
                        return subscriber.error(result.error.message)
                    }
                    if (result && createUser) {
                        if (profileData.role === ProfileRole.RO) {
                            await this.createResponseOfficerAuthUser(profileData.mobile_number!)
                            subscriber.next(result.data)
                        } else {
                            await this.createUser(profileData.email!)
                            subscriber.next(result.data)
                        }
                    }
                    return subscriber.next(result.data)
                })
            } catch (e) {
                console.warn('the error')
                console.warn(e)
                return subscriber.error('Could not create profile')
            }
        })
    }

    downLoadImage(path: string) {
        console.log(path)
        return this.supabase.storage.from('avatars').getPublicUrl(path)
    }

    uploadAvatar(filePath: string, file: File) {
        return this.supabase.storage.from('avatars').upload(filePath, file)
    }
}
